import React, { createContext, useContext, useState } from "react"
import { useMutation } from "urql"

import { useToast } from "../../contexts/ToastContext"

import { isStyleSelected } from "./StyleTab"

export const WebsiteBuilderContext = createContext()

const UPDATE_INFO_MUTATION = `
  mutation UpdateInfo(
    $name: String,
    $headline: String,
    $websiteHeadline: String,
    $subHeadline: String,
    $ctaButtonText: String,
    $defaultServiceId: ID
    $valueStatement: String,
    $quote: String,
    $quoteAuthor: String,
    $about: String,
  ) {
    updateInfo(
      name: $name,
      headline: $headline,
      websiteHeadline: $websiteHeadline,
      subHeadline: $subHeadline,
      ctaButtonText: $ctaButtonText,
      defaultServiceId: $defaultServiceId
      valueStatement: $valueStatement,
      quote: $quote,
      quoteAuthor: $quoteAuthor,
      about: $about,
    ) {
      result
      errors
      profileDetails { headline websiteHeadline subHeadline ctaButtonText defaultServiceId valueStatement quote quoteAuthor about name }
    }
  }
`

const SAVE_THEME_MUTATION = `
  mutation SaveTheme(
    $name: String,
    $colorBgDark: String,
    $colorBgLight: String,
    $colorAccent: String,
    $colorPrimary: String,
    $colorSecondary: String,
    $colorPrimaryDark: String,
    $colorSecondaryDark: String,
    $colorTextDark: String,
    $colorTextLight: String,
    $colorWhite: String,
    $colorGreyLightest: String,
    $colorGreyLight: String,
    $colorGrey: String,
    $colorGreyDark: String,
    $colorGreyDarkest: String,
    $colorBlack: String,
    $colorReviewStars: String,
    $fontHeading: String,
    $fontParagraph: String
    $preview: Boolean
   ) {
    saveTheme(
      name: $name,
      colorBgDark: $colorBgDark,
      colorBgLight: $colorBgLight,
      colorAccent: $colorAccent,
      colorPrimary: $colorPrimary,
      colorSecondary: $colorSecondary,
      colorPrimaryDark: $colorPrimaryDark,
      colorSecondaryDark: $colorSecondaryDark,
      colorTextDark: $colorTextDark,
      colorTextLight: $colorTextLight,
      colorWhite: $colorWhite,
      colorGreyLightest: $colorGreyLightest,
      colorGreyLight: $colorGreyLight,
      colorGrey: $colorGrey,
      colorGreyDark: $colorGreyDark,
      colorGreyDarkest: $colorGreyDarkest,
      colorBlack: $colorBlack,
      colorReviewStars: $colorReviewStars,
      fontHeading: $fontHeading,
      fontParagraph: $fontParagraph,
      preview: $preview
    ) {
      result
      errors
      theme {
        name
        colorBgDark
        colorBgLight
        colorAccent
        colorPrimary
        colorSecondary
        colorPrimaryDark
        colorSecondaryDark
        colorTextDark
        colorTextLight
        colorWhite
        colorGreyLightest
        colorGreyLight
        colorGrey
        colorGreyDark
        colorGreyDarkest
        colorBlack
        colorReviewStars
        fontHeading
        fontParagraph
      }
    }
  }
`

export const WebsiteBuilderProvider = ({
  practice: practiceProp,
  defaultThemeStyles,
  allStyles,
  aspectRatios,
  isImpersonating,
  children
}) => {
  const [practice, setPractice] = useState(practiceProp)
  const [lastSavedPractice, setLastSavedPractice] = useState(practiceProp)
  const [selectedTheme, setSelectedTheme] = useState(defaultThemeStyles[practice.theme.name])
  const [previewedTheme, setPreviewedTheme] = useState(selectedTheme)
  const [isPreviewing, setIsPreviewing] = useState(false)
  const [openField, setOpenField] = useState(null)
  const [highlightedField, setHighlightedField] = useState(null)
  const [selectedStyleName, setSelectedStyleName] = useState(
    allStyles.find((theme) => isStyleSelected(lastSavedPractice, theme))?.name
  )

  const [{ fetching: updateInfoFetching }, updateInfo] = useMutation(UPDATE_INFO_MUTATION)
  const [{ fetching: saveThemeFetching }, saveTheme] = useMutation(SAVE_THEME_MUTATION)
  const fetching = updateInfoFetching || saveThemeFetching
  const { showToast } = useToast()

  const onSave = () => {
    const {
      headline,
      subHeadline,
      ctaButtonText,
      defaultServiceId,
      valueStatement,
      quote,
      quoteAuthor,
      about,
      websiteHeadline
    } = practice

    updateInfo({
      name: practice.user.name,
      headline,
      subHeadline,
      ctaButtonText,
      defaultServiceId,
      valueStatement,
      quote,
      quoteAuthor,
      about,
      websiteHeadline
    }).then((result) => {
      setOpenField(null)
      if (result?.data?.updateInfo?.result === "success") {
        setLastSavedPractice(practice)
        showToast("Your profile details have been saved.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your profile details. Please try again later or contact support."
        if (result.data?.updateInfo?.errors) errorMessage += ` ${result.data.updateInfo.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const updateTheme = ({ preview, ...theme }) => {
    saveTheme({ preview, ...theme }).then((result) => {
      if (result?.data?.saveTheme?.result === "success") {
        setLastSavedPractice({ ...practice, theme })
        if (!preview) showToast("Your theme has been saved.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your theme. Please try again later or contact support."
        if (result.data?.saveTheme?.errors) errorMessage += ` ${result.data.saveTheme.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const value = {
    defaultThemeStyles,
    allStyles,
    aspectRatios,
    practice,
    setPractice,
    lastSavedPractice,
    setLastSavedPractice,
    selectedTheme,
    setSelectedTheme,
    previewedTheme,
    setPreviewedTheme,
    isPreviewing,
    setIsPreviewing,
    openField,
    setOpenField,
    highlightedField,
    setHighlightedField,
    fetching,
    onSave,
    updateTheme,
    selectedStyleName,
    setSelectedStyleName,
    isImpersonating
  }

  return <WebsiteBuilderContext.Provider value={value}>{children}</WebsiteBuilderContext.Provider>
}

export const useWebsiteBuilder = () => useContext(WebsiteBuilderContext)
