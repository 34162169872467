import React, { useEffect } from "react"
import { twMerge } from "tailwind-merge"

const Toast = ({ children, position, type, visible, setVisible, timeout = 6000 }) => {
  let timeoutId

  useEffect(() => {
    if (visible) {
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setVisible(false)
      }, timeout)
    }
  }, [visible])

  const getPositionClasses = () => {
    switch (position) {
      case "bottomLeft":
        return visible ? "bottom-8 left-8" : "bottom-8 -left-[460px]"
      case "topLeft":
        return visible ? "top-8 left-8" : "top-8 -left-[460px]"
      case "bottomCenter":
        return visible ? "bottom-8 left-1/2 -translate-x-1/2" : "-bottom-32 left-1/2 -translate-x-1/2"
      default:
        return visible ? "top-8 left-8" : "top-8 -left-[460px]"
    }
  }

  return (
    <div
      className={twMerge(
        "fixed z-[999999] max-w-[460px] rounded border px-6 py-4 duration-500 sm:w-[calc(100%-4rem)] [&>a]:cursor-pointer [&>a]:font-bold [&>a]:underline",
        type === "error"
          ? "border-red bg-red-light"
          : type === "warning"
          ? "border-orange bg-orange-light"
          : type === "info"
          ? "border-gray bg-gray-light"
          : "border-green bg-green-light",
        getPositionClasses()
      )}>
      {children}
    </div>
  )
}

export default Toast
