import { XMarkIcon } from "@heroicons/react/24/outline"
import React, { useState, useRef, useEffect } from "react"
import { ReactSortable } from "react-sortablejs"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { Label, TextArea } from "../../components/shared/Inputs"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"

import ImageUploadButton from "./ImageUploadButton"
import Thumbnail from "./Thumbnail"
import { useWebsiteBuilder } from "./WebsiteBuilderContext"

const REMOVE_GALLERY_IMAGE_MUTATION = `
  mutation RemoveGalleryImage($galleryId: ID!) {
    removeGalleryImage(galleryId: $galleryId) {
      result
      errors
    }
  }
`

const UPLOAD_GALLERY_IMAGES_MUTATION = `
  mutation AddGalleryImage($handles: [String!]!) {
    uploadGalleryImages(handles: $handles) {
      result
      errors
      galleryImages {
        id
        defaultPhotoUrl
        position
        altText
        filestackPhoto { large { webp } original { any } }
      }
    }
  }
`

const REORDER_GALLERY_IMAGES_MUTATION = `
  mutation ReorderGalleryImages($photosArray: [String!]!) {
    reorderGalleryImages(photosArray: $photosArray) {
      result
    }
  }
`

const UPDATE_GALLERY_IMAGE_MUTATION = `
  mutation UpdateGalleryImage($id: ID!, $altText: String!) {
    updateGalleryImage(id: $id, altText: $altText) {
      result
    }
  }
`

const GalleryUpload = () => {
  const [editing, setEditing] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteImageId, setDeleteImageId] = useState(null)
  const [galleryImage, setGalleryImage] = useState(null)
  const [altText, setAltText] = useState("")
  const containerRef = useRef(null)

  const { practice, setPractice, openField, setOpenField, lastSavedPractice, setHighlightedField, aspectRatios } =
    useWebsiteBuilder()
  const [{ fetching: removeImageFetching }, removeGalleryImage] = useMutation(REMOVE_GALLERY_IMAGE_MUTATION)
  const [{ fetching: uploadImages }, uploadGalleryImages] = useMutation(UPLOAD_GALLERY_IMAGES_MUTATION)
  const [, reorderGalleryImages] = useMutation(REORDER_GALLERY_IMAGES_MUTATION)
  const [{ fetching: updateGalleryImageFetching }, updateGalleryImage] = useMutation(UPDATE_GALLERY_IMAGE_MUTATION)
  const { showToast } = useToast()

  const name = "Gallery photos"
  const fetching = removeImageFetching || uploadImages
  const disabled = openField && openField !== name
  const photos = practice.galleryImages

  const aspectRatio = aspectRatios?.[practice.theme.name]?.["gallery"] ?? null

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target) && openField === name) {
        // setEditing(false)
        // setOpenField(null)
        // setHighlightedField(null)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [openField, name, setOpenField, setHighlightedField])

  const onCancel = () => {
    setOpenField(null)
    setPractice(lastSavedPractice)
    setHighlightedField(null)
  }

  const onOpened = () => setOpenField(name)

  const handleSave = async () => {
    if (galleryImage && altText !== galleryImage.altText) {
      const result = await updateGalleryImage({
        id: galleryImage.id,
        altText
      })

      if (result?.data?.updateGalleryImage?.result === "success") {
        setPractice((prev) => ({
          ...prev,
          galleryImages: prev.galleryImages.map((img) => (img.id === galleryImage.id ? { ...img, altText } : img))
        }))
      } else {
        showToast({
          type: "error",
          content: "Failed to update alt text"
        })
        return
      }
    }

    showToast("Your image alt text has been saved.")
  }

  const handleUploadImage = ({ handles }) => {
    uploadGalleryImages({
      handles
    }).then((result) => {
      if (result?.data?.uploadGalleryImages?.result === "success") {
        setPractice((prev) => ({
          ...prev,
          galleryImages: result.data.uploadGalleryImages.galleryImages
        }))
        showToast("Your photo has been saved.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your profile photo. Please try again later or contact support."
        if (result.data?.uploadGalleryImages?.errors) errorMessage += ` ${result.data.uploadGalleryImages.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const removePhoto = (galleryId) => {
    removeGalleryImage({ galleryId }).then((result) => {
      if (result?.data?.removeGalleryImage?.result === "success") {
        setPractice((prev) => ({
          ...prev,
          galleryImages: prev.galleryImages.filter((photo) => photo.id !== galleryId)
        }))
        showToast("Successfully deleted photo")
      } else {
        showToast({ type: "error", content: "Failed to delete photo" })
        console.error(result) // eslint-disable-line no-console
      }
    })
  }

  return (
    <>
      <div ref={containerRef} className={twMerge("bg-white", disabled ? "opacity-50" : "")}>
        {editing ? (
          <div>
            <div className="mb-2">
              <div className="mb-1 font-bold">{name}</div>
            </div>
            <p className="mb-4 text-sm text-gray-dark">
              Showcase your work, environment, or results with a selection of high-quality images that tell your story.
            </p>
            <ReactSortable
              className="flex flex-wrap gap-2"
              list={photos}
              setList={(newPositions) => {
                if (JSON.stringify(newPositions) !== JSON.stringify(photos)) {
                  const newOrder = newPositions.map((photo, i) => JSON.stringify({ id: photo.id, position: i + 1 }))
                  reorderGalleryImages({ photosArray: newOrder }).then((result) => {
                    if (result?.data?.reorderGalleryImages?.result !== "success") {
                      console.error(result) // eslint-disable-line no-console
                      showToast({
                        type: "error",
                        content:
                          "There was error reordering your credentials. Please try again later or contact support if the error persists."
                      })
                    }
                  })
                  setPractice((prev) => ({
                    ...prev,
                    galleryImages: newPositions
                  }))
                }
              }}>
              {photos.map((photo) => (
                <div
                  key={photo.id}
                  className={`relative flex h-20 w-20 cursor-grab items-center justify-center rounded-lg border ${
                    galleryImage?.id === photo.id ? "border-teal" : "border-gray"
                  } hover:border-teal`}
                  onClick={() => {
                    setGalleryImage(photo)
                    setAltText(photo.altText)
                  }}>
                  <button
                    className="absolute right-1.5 top-1.5 flex h-4 w-4 items-center justify-center rounded bg-white text-gray-dark hover:bg-teal hover:text-white"
                    onClick={() => {
                      setDeleteImageId(photo.id)
                      setDeleteModalVisible(true)
                    }}>
                    <XMarkIcon className="h-full w-full stroke-2" />
                  </button>
                  <img
                    src={photo.defaultPhotoUrl}
                    alt="Gallery photo"
                    className="aspect-square w-[calc(100%-4px)] rounded-lg"
                  />
                </div>
              ))}
            </ReactSortable>
            {galleryImage && (
              <div className="mt-4">
                <Label htmlFor="alt-text">Alt text</Label>
                <TextArea id="alt-text" value={altText} onChange={(e) => setAltText(e.target.value)} />
              </div>
            )}
            <div className="mt-4 flex justify-between">
              <ImageUploadButton
                practice={practice}
                handleUploadImage={handleUploadImage}
                fetching={fetching}
                showCurateTab={false}
                hideS3Images={true}
                aspectRatio={aspectRatio}
              />
              <div className="flex justify-end gap-4">
                <Button
                  disabled={fetching}
                  type="tertiary"
                  size="small"
                  onClick={() => {
                    setEditing(false)
                    onCancel()
                  }}>
                  Cancel
                </Button>
                <Button disabled={fetching || updateGalleryImageFetching} size="small" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex">
            <div className="mr-4 flex-1">
              <Typography variant="title">{name}</Typography>
              <div className="flex flex-wrap gap-2">
                {photos.map((photo) => (
                  <Thumbnail key={photo.id} photoUrl={photo.defaultPhotoUrl} />
                ))}
              </div>
            </div>
            <div className="flex-none">
              <button
                className={twMerge("font-bold text-teal underline", disabled ? "pointer-events-none" : "")}
                onClick={() => {
                  if (disabled) return
                  setEditing(true)
                  onOpened(name)
                  setHighlightedField(name)
                  document.getElementById("gallery-photos")?.scrollIntoView({ behavior: "smooth" })
                }}>
                Edit
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Delete gallery image modal */}
      <AnimatedModal
        visible={deleteModalVisible}
        hideModal={() => {
          setDeleteModalVisible(false)
          setDeleteImageId(null)
        }}
        showFooter={true}
        header="Delete gallery image"
        actionButtonCopy="Delete"
        actionButtonType="destructive"
        onSave={() => {
          setDeleteModalVisible(false)
          removePhoto(deleteImageId)
        }}>
        <div>
          <Typography variant="body">Are you sure you want to delete this image?</Typography>
        </div>
      </AnimatedModal>
    </>
  )
}

export default GalleryUpload
