import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import PropTypes from "prop-types"
import React, { useState, useEffect, Fragment } from "react"
import ReactModal from "react-modal"
import styled from "styled-components"
import { twMerge } from "tailwind-merge"

import { Button, CloseButton } from "./Buttons"
import DesktopH5 from "./DesktopH5"
import DesktopTitle3 from "./DesktopTitle3"
import HealMeSquareButton from "./HealMeSquareButton"
import Typography from "./Typography"

const ModalContent = styled.div`
  margin: ${(props) => props.contentMargin};
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(80vh - ${(props) => props.headerHeight}px - ${(props) => props.footerHeight}px - 164px);
  @media (min-width: 640px) and (max-width: 767px) {
    max-height: calc(80vh - ${(props) => props.headerHeight}px - ${(props) => props.footerHeight}px - 104px);
  }
  @media (max-width: 767px) {
    max-height: calc(100vh - ${(props) => props.headerHeight}px - ${(props) => props.footerHeight}px - 164px);
  }
`
// DEPRECATED: use AnimatedModal instead
const Modal = ({
  closeModal,
  open,
  children,
  header,
  footer,
  hideFooter,
  handleClick,
  size = "large",
  wide = false,
  contentMargin = "32px 32px 140px 32px"
}) => {
  const [dimension, updateDimension] = useState({ width: 0, height: 0 })

  useEffect(() => {
    updateDimension({
      ...dimension,
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [window.innerWidth])

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: size === "large" || wide ? "60vw" : "40vw",
      padding: "0",
      borderColor: "#E4EFF6",
      height: size === "large" ? "80vh" : "fit-content",
      overflow: "none"
    },
    overlay: { zIndex: 9999, backgroundColor: "rgba(0,0,0,0.6)" }
  }

  const mobileCustomStyle = {
    content: {
      top: "0",
      left: "0",
      right: "auto",
      bottom: "auto",
      marginRight: "0",
      transform: "none",
      inset: "none",
      width: "100vw",
      padding: "0",
      borderColor: "#E4EFF6",
      height: size === "small" ? "100vh" : "100vh",
      overflow: "none",
      position: "fixed",
      overflowY: "scroll"
    },
    overlay: { zIndex: 99993, backgroundColor: "rgba(0,0,0,0.6)" }
  }

  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerEl, setHeaderEl] = useState()

  const [footerHeight, setFooterHeight] = useState(0)
  const [footerEl, setFooterEl] = useState()

  useEffect(() => {
    setHeaderHeight(headerEl?.clientHeight)
    setFooterHeight(footerEl?.clientHeight)
  }, [headerEl, footerEl])

  const mdBreakpoint = 767
  return (
    <div>
      <ReactModal
        isOpen={open}
        onRequestClose={closeModal}
        style={dimension?.width < mdBreakpoint ? mobileCustomStyle : customStyles}
        ariaHideApp={false}>
        <div className="relative h-screen">
          <div className="CloseIcon absolute right-4 top-3 hover:cursor-pointer">
            <i className="icon-close text-lg font-semibold " onClick={closeModal}></i>
          </div>
          {header && (
            <div className="ModalHeader p-8 pb-0">
              <div
                ref={(el) => {
                  setHeaderEl(el)
                }}>
                {header}
              </div>
            </div>
          )}
          <ModalContent
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            contentMargin={dimension?.width < mdBreakpoint ? "32px" : contentMargin}>
            {children}
          </ModalContent>
          <div
            ref={(el) => {
              setFooterEl(el)
            }}>
            {!hideFooter && (
              <>
                {footer ? (
                  <div className="fixed inset-x-0 bottom-0 flex w-full justify-between border-t border-gray bg-white p-6">
                    <div className="flex w-full items-center justify-between">{footer}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingBottom: size === "large" && dimension?.width < mdBreakpoint ? "72px" : "24px"
                    }}
                    className="fixed inset-x-0 bottom-0 flex w-full justify-between border-t border-gray bg-white p-6">
                    <div className="flex w-full items-center justify-between">
                      <HealMeSquareButton type="primary" label="Done" onClick={handleClick} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

Modal.propTypes = {
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  hideFooter: PropTypes.bool,
  handleClick: PropTypes.func,
  size: PropTypes.oneOf(["large", "small"]),
  wide: PropTypes.bool,
  contentMargin: PropTypes.string
}

export default Modal

export const SaveOrCancelFooter = ({ onSave, onCancel, loading, cancelLabel = "Cancel", saveLabel = "Save" }) => (
  <>
    <HealMeSquareButton type="tertiary" onClick={() => onCancel()} label={cancelLabel} />
    <HealMeSquareButton type="primary" loading={loading} onClick={() => onSave()} label={saveLabel} />
  </>
)

export const ConfirmCancelModal = NiceModal.create(({ loading }) => {
  const modal = useModal()

  return (
    <Modal
      open={modal.visible}
      closeModal={() => modal.hide()}
      size="small"
      header={
        <div>
          <DesktopH5 content="Cancel appointment" />
        </div>
      }
      footer={
        <>
          <HealMeSquareButton type="tertiary" onClick={() => modal.hide()} label="Go back" />
          <HealMeSquareButton
            type="warning"
            loading={loading}
            onClick={() => modal.resolve()}
            label="Cancel appointment"
          />
        </>
      }>
      <DesktopTitle3 content={"Are you sure you want to cancel your appointment?"} />
      <p className="help-text">This appointment will immediately be cancelled and the practitioner will be notified.</p>
    </Modal>
  )
})

// This code is based on https://tailwindui.com/components/application-ui/overlays/modals
export const NewModal = NiceModal.create(({ children, ...rest }) => {
  const modal = useModal()

  return (
    <AnimatedModal visible={modal.visible} hideModal={() => modal.remove()} {...rest}>
      {children}
    </AnimatedModal>
  )
})

// TODO: for some reason the NewModal using NiceModal doesn't animate. This one uses the same code
//   except it using React state to manage the visibility instead of NiceModal.
export const AnimatedModal = ({
  visible,
  hideModal,
  children,
  header,
  showFooter = false,
  footerButtons = [],
  cancelButtonCopy = "Cancel",
  cancelButtonType = "tertiary",
  actionButtonCopy = "Save",
  actionButtonType = "primary",
  onSave,
  fullHeightInMobile = false,
  saveDisabled = false,
  hideSaveButton = false,
  hideCancelButton = false,
  overflowVisible = false,
  unmount = true,
  animate = true,
  dialogClassName = "",
  hideCloseButton = false
}) => (
  <Transition show={visible} unmount={unmount}>
    <Dialog className="relative z-[19990]" onClose={hideModal} unmount={unmount}>
      <TransitionChild
        unmount={unmount}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed inset-0 bg-black/50 transition-opacity" />
      </TransitionChild>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex h-screen min-h-screen p-0 text-center min_sm:items-center min_sm:justify-center">
          <TransitionChild
            unmount={unmount}
            enter={animate ? "transition ease-in duration-300" : ""}
            enterFrom={animate ? "translate-y-[500px] md:translate-y-full" : ""}
            enterTo="translate-y-0"
            leave="ease-out duration-300"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-[500px] md:translate-y-full">
            <DialogPanel
              className={twMerge(
                "relative h-fit w-[560px] overflow-hidden rounded-xl bg-white p-8 text-left shadow-xl transition-all md:max-h-[100vh] md:w-full md:overflow-y-auto md:rounded-none",
                fullHeightInMobile ? "md:min-h-screen" : "md:absolute md:bottom-0 md:rounded-t-xl",
                showFooter ? "pb-[112px]" : "",
                overflowVisible ? "overflow-visible" : "",
                dialogClassName
              )}>
              {header ? (
                <div className="mb-4 flex items-center justify-between">
                  <Typography variant="h3" as="h2">
                    {header}
                  </Typography>
                  {!hideCloseButton && <CloseButton onClick={hideModal} />}
                </div>
              ) : !hideCloseButton ? (
                <CloseButton className="absolute right-8 top-8 z-20" onClick={hideModal} />
              ) : null}
              {children}
              {showFooter && (
                <div className="absolute inset-x-0 bottom-0 flex justify-between border-t border-gray-light bg-white p-8 md:fixed">
                  <div className="flex w-full items-center justify-end gap-4">
                    {!hideCancelButton && (
                      <Button type={cancelButtonType} onClick={hideModal}>
                        {cancelButtonCopy}
                      </Button>
                    )}
                    {footerButtons.map((button) => {
                      if (button.show === false) return null
                      return (
                        <Button
                          key={button.label}
                          type={button.type}
                          onClick={button.onClick}
                          disabled={button.disabled}>
                          {button.label}
                        </Button>
                      )
                    })}
                    {!hideSaveButton && (
                      <Button type={actionButtonType} onClick={onSave} disabled={saveDisabled}>
                        {actionButtonCopy}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </Transition>
)

export const MobileFullScreenModal = ({
  visible,
  hideModal,
  children,
  header,
  showFooter = false,
  cancelButtonCopy = "Cancel",
  cancelButtonType = "tertiary",
  actionButtonCopy = "Save",
  actionButtonType = "primary",
  onSave,
  onCancel,
  saveDisabled = false,
  hideSaveButton = false,
  overflowVisible = false,
  unmount = true
}) => (
  <Dialog as="div" className="relative z-[99999]" onClose={hideModal} unmount={unmount} open={visible}>
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex h-screen min-h-screen p-0 text-center min_sm:items-center min_sm:justify-center">
        <DialogPanel
          className={twMerge(
            "relative h-fit w-[560px] rounded-xl bg-white p-8 text-left shadow-xl transition-all md:min-h-screen md:w-full md:rounded-none",
            showFooter ? "pb-[112px]" : "",
            overflowVisible ? "overflow-visible" : "overflow-hidden"
          )}>
          {header ? (
            <div className="mb-4 flex items-center justify-between">
              <Typography variant="h3" as="h2">
                {header}
              </Typography>
              <CloseButton onClick={hideModal} />
            </div>
          ) : (
            <CloseButton className="absolute right-8 top-8 z-20" onClick={hideModal} />
          )}
          {children}
          {showFooter && (
            <div className="fixed inset-x-0 bottom-0 flex justify-between border-t border-gray-light bg-white p-8">
              <div className="flex w-full items-center justify-end gap-4">
                <Button type={cancelButtonType} onClick={onCancel || hideModal}>
                  {cancelButtonCopy}
                </Button>
                {!hideSaveButton && (
                  <Button type={actionButtonType} onClick={onSave} disabled={saveDisabled}>
                    {actionButtonCopy}
                  </Button>
                )}
              </div>
            </div>
          )}
        </DialogPanel>
      </div>
    </div>
  </Dialog>
)

export const ModalBackdrop = ({ children, visible, hideModal }) => (
  <Transition show={visible} as={Fragment}>
    <Dialog as="div" className="relative z-[99999]" onClose={hideModal} onClick={hideModal}>
      <TransitionChild
        as="div"
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed inset-0 bg-black/80 transition-opacity"></div>
        <button
          className="fixed right-8 z-[100000] flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-dark hover:bg-gray-light focus:outline-none focus:ring-2 focus:ring-blue active:bg-gray sm:bottom-8 min_sm:top-8"
          onClick={hideModal}>
          <XMarkIcon className="h-6 w-6" />
        </button>
      </TransitionChild>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <TransitionChild
          as="div"
          className="relative"
          enter="transition ease-in duration-300"
          enterFrom="translate-y-[800px]"
          enterTo="translate-y-0"
          leave="ease-out duration-300"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-[800px]">
          {children}
        </TransitionChild>
      </div>
    </Dialog>
  </Transition>
)
