import React from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"

import AboutMeContainer from "./AboutMeContainer"

const AboutSection = ({
  practice,
  theme,
  highlight,
  viewingInBuilder,
  backgroundColor,
  cardBackgroundColor,
  credentialsBackgroundColor,
  credentialsClassName,
  cardClassName
}) => {
  backgroundColor ||= theme.colorBgLight
  const [isVisible, containerRef] = useIntersectionObserver()

  const alt = practice.websiteMetadata?.primaryModality
    ? `${practice.user.name} practicing ${practice.websiteMetadata.primaryModality} in ${practice.city}, ${practice.stateAbbreviation}`
    : `${practice.user.name} in ${practice.city}, ${practice.stateAbbreviation}`

  return (
    <section id="about" style={{ backgroundColor, color: theme.colorTextDark }}>
      <div
        className="container mx-auto flex items-center justify-between py-10 md:flex-col md:gap-0 md:px-5"
        ref={containerRef}>
        <img
          src={practice.user.profilePhotoUrl}
          alt={alt}
          className={twMerge(
            "aspect-square w-1/2 transition-all duration-[1500ms] md:mt-8 md:w-[240px]",
            highlight === "Headshot" ? "rounded ring-4 ring-teal" : "",
            isVisible
              ? "translate-x-0 translate-y-0 opacity-100"
              : "opacity-0 min_sm:-translate-x-full min_sm:-translate-y-full"
          )}
        />
        <AboutMeContainer
          practice={practice}
          theme={theme}
          isVisible={isVisible}
          className={cardClassName}
          cardBackgroundColor={cardBackgroundColor}
          credentialsBackgroundColor={credentialsBackgroundColor}
          credentialsClassName={credentialsClassName}
          viewingInBuilder={viewingInBuilder}
        />
      </div>
    </section>
  )
}

export default AboutSection
