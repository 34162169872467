import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import { practiceCtaText, practiceDefaultServiceId } from "../../@core/practice/practice.utils"
import AdditionalContent from "../ProProfileShared/AdditionalContent"
import AnimatedSection from "../ProProfileShared/AnimatedSection"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const ValueStatementSection = ({ practice, theme, highlight, viewingInBuilder }) => {
  const { valueStatement } = practice
  const valueStatementImage = practice.imageAssets.find((asset) => asset.description === "value_statement")

  return (
    <section
      id="value-statement"
      style={{ backgroundColor: theme.colorSecondary }}
      className="w-full py-24 md:px-5 md:py-16">
      <AnimatedSection className="container mx-auto flex w-full items-center justify-between gap-10 md:flex-col-reverse">
        <div className="w-1/2 px-6 py-10 md:w-full" style={{ backgroundColor: theme.colorWhite }}>
          <div
            className={twMerge(highlight === "valueStatement" ? DEFAULT_HIGHLIGHT_CLASSES : "")}
            style={{ color: theme.colorTextDark }}>
            <AdditionalContent valueStatement={valueStatement} />
          </div>
          <div className="mt-8 text-left md:text-center">
            <WebAppBookNow
              practiceId={practice.id}
              serviceId={practiceDefaultServiceId(practice)}
              buttonCopy={practiceCtaText(practice)}
              buttonClasses={twMerge(
                "border border-[var(--bg-color)] px-10 py-3 hover:border-[var(--hover-color)] md:w-full",
                theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
                viewingInBuilder ? "pointer-events-none" : ""
              )}
              style={{
                color: theme.colorTextLight,
                backgroundColor: theme.colorAccent,
                "--bg-color": theme.colorAccent,
                "--hover-color": theme.colorTextLight
              }}
            />
          </div>
        </div>
        <div className="w-1/2 md:w-full">
          <img
            src={valueStatementImage.resizedImageUrl}
            alt={valueStatementImage.altText}
            className="aspect-square w-full object-cover"
          />
        </div>
      </AnimatedSection>
    </section>
  )
}

export default ValueStatementSection
