import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import dayjs from "dayjs"
import React, { useState, forwardRef, Fragment, useRef, useEffect, useImperativeHandle } from "react"
import Switch from "react-switch"
import MaskedInput from "react-text-mask"
import { twMerge } from "tailwind-merge"
import { createNumberMask } from "text-mask-addons"

import { sanitize } from "../../utils/utils"

import { Button } from "./Buttons"
import Typography from "./Typography"

export const VALIDATION_ERROR_INPUT_CLASSNAMES =
  "border border-red hover:z-20 hover:border-red focus:z-20 focus:border-red focus:outline-none focus:ring-2 focus:ring-red/50"

const formatPhoneNumber = (value) => {
  const digits = value.replace(/\D/g, "")
  const match = digits.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/)
  if (match) {
    return `(${match[1]}${match[2] ? ") " : ""}${match[2]}${match[3] ? "-" : ""}${match[3]}`
  }
  return value
}

export const BASE_INPUT_CLASSNAMES = twMerge(
  "w-full rounded-lg border border-gray px-3 py-1.5 placeholder:text-gray-dark hover:border-black focus:border-black focus:outline-none focus:ring-4 focus:ring-blue disabled:cursor-not-allowed disabled:bg-gray-ultralight disabled:text-gray-dark"
)
export const BASE_LABEL_CLASSNAMES = twMerge("mb-1 cursor-pointer text-sm font-bold")
export const DROPDOWN_CLASSNAMES = twMerge(
  "z-50 mt-1 max-h-72 w-full overflow-auto rounded-lg border-2 border-gray bg-white py-2 focus:outline-none"
)
export const DROPDOWN_OPTION_CLASSNAMES = ({ active, selected }) =>
  twMerge(
    "relative cursor-pointer select-none py-2 pl-3 pr-9",
    selected ? "bg-gray" : active ? "bg-gray-ultralight" : ""
  )

export const Input = forwardRef(({ type, className, validationError, explanatorySubtext, ...rest }, ref) => {
  className = twMerge(className, validationError && VALIDATION_ERROR_INPUT_CLASSNAMES)

  const handleChange = (e) => {
    if (type === "phone") {
      e.target.value = formatPhoneNumber(e.target.value)
    }
    if (rest.onChange) {
      rest.onChange(e)
    }
  }

  const inputProps = {
    ...rest,
    type,
    inputMode: type === "phone" ? "numeric" : undefined,
    placeholder: type === "phone" ? "(___) ___-____" : undefined
  }

  return (
    <>
      <input
        {...inputProps}
        {...rest}
        ref={ref}
        className={twMerge(BASE_INPUT_CLASSNAMES, className)}
        onChange={handleChange}
      />
      {validationError && !!validationError.length && <ValidationError>{validationError}</ValidationError>}
      {explanatorySubtext && <Typography variant="micro">{explanatorySubtext}</Typography>}
    </>
  )
})
Input.displayName = "Input"

export const ValidationError = ({ className, children }) => (
  <div className={twMerge("mt-1 flex items-center gap-1", className)}>
    <ExclamationCircleIcon className="h-5 w-5 text-gray-dark" />
    <span className="text-[13px] text-red">{children}</span>
  </div>
)

export const Label = ({ className, children, ...rest }) => (
  // TODO: mb-0 class is needed to override styles in forms.sass, which we can remove when that's gone
  <label {...rest} className={twMerge(BASE_LABEL_CLASSNAMES, className)}>
    {children}
  </label>
)

export const InputWithLabel = ({
  id,
  label,
  className,
  inputClassName,
  labelClassName,
  required,
  validationError,
  ...rest
}) => {
  id ||= "input-" + label.toLowerCase().replace(" ", "-")

  return (
    <div className={className}>
      <Label className={labelClassName} htmlFor={id}>
        {label} {required && <span className="text-red">*</span>}
      </Label>
      <Input id={id} className={inputClassName} validationError={validationError} {...rest} />
    </div>
  )
}

export const CurrencyInput = ({ value, onChange, validationError, ...rest }) => {
  const handleChange = (event) => {
    onChange(event.target.value.replace(/,/g, "") * 100)
  }
  const adjustedValue = value === null || value === undefined ? "" : Number((value / 100).toFixed(2)).toString()

  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2,
    allowNegative: false,
    allowLeadingZeroes: false,
    placeholderChar: ""
  }
  return (
    <div className="relative">
      <div className="pointer-events-none absolute left-0 top-[6.3px] pl-3">
        <span className="text-gray-dark">$</span>
      </div>
      <MaskedInput
        mask={createNumberMask(defaultMaskOptions)}
        value={adjustedValue}
        onChange={handleChange}
        className={twMerge(BASE_INPUT_CLASSNAMES, "pl-7", validationError && VALIDATION_ERROR_INPUT_CLASSNAMES)}
        {...rest}
      />
      {validationError && <ValidationError>{validationError}</ValidationError>}
    </div>
  )
}

const ToggleButton = ({ className, label, selected, onClick }) => (
  <button
    role="radio"
    onClick={onClick}
    className={twMerge(
      `flex-1 border border-gray-light py-1.5 text-center focus:outline-blue active:bg-gray-light ${
        selected ? "cursor-not-allowed bg-white font-bold" : "bg-gray-ultralight"
      }`,
      className
    )}>
    {label}
  </button>
)

export const ToggleButtonGroup = ({ className, onChange, value, options }) => (
  <div className={twMerge("flex items-center justify-center", className)}>
    {options.map((option, index) => (
      <ToggleButton
        key={option.value}
        className={index === 0 ? "rounded-l-lg" : index === options.length - 1 ? "rounded-r-lg" : ""}
        label={option.label}
        selected={option.value === value}
        onClick={() => onChange(option.value)}
      />
    ))}
  </div>
)

export const EditableValue = React.forwardRef(
  (
    {
      name,
      value,
      onSave = () => {},
      onCancel = () => {},
      onOpened = () => {},
      onClosed = () => {},
      className,
      titleClassName,
      disabled,
      saveDisabled,
      editable = true,
      children,
      editButtonCopy = "Edit",
      truncateLength = 190,
      hint
    },
    ref
  ) => {
    const [editing, setEditing] = useState(false)

    useImperativeHandle(ref, () => ({
      setEditing
    }))

    if (value && truncateLength && value.length > truncateLength) {
      value = value.slice(0, 190) + "..."
    }

    return (
      <div className={twMerge("bg-white", className, disabled ? "opacity-50" : "")}>
        {editing ? (
          <div>
            <div className="mb-2">
              <div className="mb-1 font-bold">{name}</div>
              {hint && (
                <Typography variant="smSubtitle" className="mb-3">
                  {hint}
                </Typography>
              )}
            </div>
            {children}
            <div className="mt-4 flex justify-end gap-4">
              <Button
                type="tertiary"
                size="small"
                onClick={() => {
                  setEditing(false)
                  onCancel()
                  onClosed()
                }}>
                Cancel
              </Button>
              <Button
                disabled={saveDisabled}
                size="small"
                onClick={() => {
                  if (saveDisabled) return
                  setEditing(false)
                  onSave()
                  onClosed()
                }}>
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex">
            <div className="mr-4 flex-1">
              <Typography variant="title" className={titleClassName}>
                {name}
              </Typography>
              <Typography variant="smSubtitle" className="max-w-[445px] break-words">
                <div dangerouslySetInnerHTML={{ __html: sanitize(value) || "Not set" }} />
              </Typography>
            </div>
            {editable && (
              <div className="flex-none">
                <button
                  className={twMerge("font-bold text-teal underline", disabled ? "pointer-events-none" : "")}
                  onClick={() => {
                    if (disabled) return
                    setEditing(true)
                    onOpened(name)
                  }}>
                  {editButtonCopy}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)
EditableValue.displayName = "EditableValue"

export const ShortUrlInput = (props) => (
  <div className="relative">
    <div className="absolute left-[15px] top-[7px]">https://heal.me/</div>
    <Input className="pl-[133.5px]" {...props} />
  </div>
)

export const Select = ({ className, disabled, children, options, value, defaultOption, validationError, ...rest }) => {
  if (options && children) throw "NativeSelect can't have both options and children"

  return (
    <div className={twMerge("relative", className)}>
      <select
        className={twMerge(
          BASE_INPUT_CLASSNAMES,
          "appearance-none bg-white pr-8",
          defaultOption && value === "" ? "text-gray-dark" : "",
          disabled ? "pointer-events-none" : "",
          validationError && VALIDATION_ERROR_INPUT_CLASSNAMES
        )}
        value={value}
        disabled={disabled}
        {...rest}>
        {options ? (
          <>
            {defaultOption && (
              <option disabled value={""}>
                {defaultOption}
              </option>
            )}
            {options.map((option) => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </>
        ) : (
          <>{children}</>
        )}
      </select>
      <ChevronDownIcon
        className="pointer-events-none absolute right-2.5 top-2.5 h-5 w-5 text-gray-dark"
        aria-hidden="true"
      />
      {validationError && <ValidationError>{validationError}</ValidationError>}
    </div>
  )
}

export const TextArea = forwardRef(
  ({ className, validationError, rows = 3, maxLength, value, onChange, ...rest }, ref) => {
    className = twMerge(className, validationError && VALIDATION_ERROR_INPUT_CLASSNAMES)

    const textAreaRef = ref || useRef(null)

    const autoResize = () => {
      const textarea = textAreaRef.current
      if (textarea) {
        textarea.style.height = "auto"
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }

    const handleChange = (e) => {
      if (!maxLength || e.target.value.length <= maxLength) {
        onChange(e)
        autoResize()
      }
    }

    useEffect(() => {
      autoResize()
    }, [value])

    return (
      <>
        <textarea
          rows={rows}
          ref={textAreaRef}
          value={value}
          onChange={handleChange}
          {...rest}
          style={{ overflow: "hidden", resize: "none" }}
          className={twMerge(BASE_INPUT_CLASSNAMES, className)}
        />
        {maxLength > 0 && (
          <div className="text-right text-sm">
            {value.length}/{maxLength}
          </div>
        )}
        {validationError && <ValidationError>{validationError}</ValidationError>}
      </>
    )
  }
)

TextArea.displayName = "TextArea"

export const SearchMagnifyingGlass = () => (
  <MagnifyingGlassIcon className="absolute right-2.5 top-2 h-6 w-6 text-gray-dark opacity-[0.64]" />
)

export const SearchInput = ({ containerClassName, ...props }) => (
  <div className={twMerge("relative w-[300px] sm:w-full", containerClassName)}>
    <Input {...props} />
    <SearchMagnifyingGlass />
  </div>
)

export const Toggle = ({ checked, onChange, ...rest }) => (
  <Switch
    checked={checked}
    onChange={onChange}
    offColor="#C8D6DF"
    onColor="#0D9DA4"
    uncheckedIcon={false}
    checkedIcon={false}
    height={24}
    width={45}
    {...rest}
  />
)

export const TimePicker = ({
  value,
  onChange,
  disabled,
  showFifteenIncrements = false,
  valueFormat = "HH:mm",
  labelFormat = "h:mm a",
  startAt = "00:00"
}) => {
  const availableTimes = []
  const startTime = dayjs(`2024-01-01T${startAt}`)

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += showFifteenIncrements ? 15 : 30) {
      const currentTime = dayjs(`2024-01-01T${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`)

      if (currentTime.isBefore(startTime)) continue

      availableTimes.push({
        label: currentTime.format(labelFormat),
        value: currentTime.format(valueFormat)
      })
    }
  }

  return (
    <Select
      options={availableTimes}
      value={value}
      onChange={onChange}
      disabled={disabled}
      defaultOption="Select a time"
    />
  )
}

export const CheckBox = ({ label, onChange, id, checked, className }) => {
  const ref = useRef()
  id ||= "checkbox-" + label.toLowerCase().replace(" ", "-")

  return (
    <label
      htmlFor={id}
      className={twMerge(`inline-flex cursor-pointer gap-2 text-base ${checked ? "font-bold" : ""}`, className)}
      onClick={() => (ref.current.checked = !ref.current.checked)}>
      <input ref={ref} checked onChange={onChange} type="checkbox" id={id} className="hidden" />
      {checked ? (
        <CheckIcon className="inline-block h-6 w-6 flex-none rounded bg-gray-dark stroke-2 text-white" />
      ) : (
        <div className="inline-block h-6 w-6 flex-none rounded border border-gray bg-white" />
      )}
      {label}
    </label>
  )
}
