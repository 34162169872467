import camelCase from "lodash/camelCase"
import React, { useState, useRef, useEffect } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { Label, TextArea } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"

import ImageUploadButton from "./ImageUploadButton"
import Thumbnail from "./Thumbnail"
import { useWebsiteBuilder } from "./WebsiteBuilderContext"

export const UPLOAD_IMAGE_MUTATION = `
  mutation UploadImage($handle: String!, $description: String!, $provider: String) {
    uploadImage(handle: $handle, description: $description, provider: $provider) {
      result
      errors
      photoUrl
      altText
    }
  }
`

export const UPDATE_IMAGE_ASSET_MUTATION = `
  mutation UpdateImageAsset($id: ID!, $altText: String!) {
    updateImageAsset(id: $id, altText: $altText) {
      result
      errors
    }
  }
`

const HeroImageUpload = ({ name, fieldName, description, scrollToId, showAltText = true }) => {
  const [editing, setEditing] = useState(false)
  const containerRef = useRef(null)
  const { practice, setPractice, openField, setOpenField, setHighlightedField, aspectRatios, isImpersonating } =
    useWebsiteBuilder()
  const [{ fetching }, uploadImage] = useMutation(UPLOAD_IMAGE_MUTATION)
  const [{ fetching: updating }, updateImageAsset] = useMutation(UPDATE_IMAGE_ASSET_MUTATION)

  let photoUrl = practice[fieldName]
  const imageAsset = practice.imageAssets.find((image) => image.description === description)
  const [altText, setAltText] = useState(imageAsset?.altText)
  if (!photoUrl) {
    photoUrl = imageAsset?.resizedImageUrl
  }
  const disabled = openField && openField !== name

  const aspectRatio = aspectRatios?.[practice.theme.name]?.[camelCase(description)] ?? null

  const { showToast } = useToast()

  const handleUploadImage = ({ handles, provider }) => {
    uploadImage({
      handle: handles[0],
      description,
      provider
    }).then((result) => {
      if (result?.data?.uploadImage?.result === "success") {
        const existingImage = practice.imageAssets.find((img) => img.description === description)

        if (existingImage) {
          setPractice((prev) => ({
            ...prev,
            imageAssets: prev.imageAssets.map((img) =>
              img.id === existingImage.id
                ? {
                    ...img,
                    altText: result.data.uploadImage.altText,
                    resizedImageUrl: result.data.uploadImage.photoUrl
                  }
                : img
            )
          }))
          setAltText(result.data.uploadImage.altText)
        } else if (practice[fieldName]) {
          setPractice((prev) => ({
            ...prev,
            [fieldName]: result.data.uploadImage.photoUrl
          }))
        } else {
          setPractice((prev) => {
            const newImageAsset = {
              id: imageAsset?.id,
              description,
              altText: result.data.uploadImage.altText,
              resizedImageUrl: result.data.uploadImage.photoUrl
            }

            return {
              ...prev,
              imageAssets: [...prev.imageAssets, newImageAsset]
            }
          })
          setAltText(result.data.uploadImage.altText)
        }

        setAltText(result.data.uploadImage.altText)
        showToast("Your image has been saved.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your hero image. Please try again later or contact support."
        if (result.data?.uploadImage?.errors) errorMessage += ` ${result.data.uploadImage.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  const handleSave = async () => {
    if (imageAsset && altText !== imageAsset.altText) {
      const result = await updateImageAsset({
        id: imageAsset.id,
        altText
      })

      if (result?.data?.updateImageAsset?.result === "success") {
        setPractice((prev) => ({
          ...prev,
          imageAssets: prev.imageAssets.map((img) => (img.id === imageAsset.id ? { ...img, altText } : img))
        }))
      } else {
        showToast({
          type: "error",
          content: "Failed to update alt text"
        })
        return
      }
    }

    showToast("Your image alt text has been saved.")
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target) && openField === name) {
        // setEditing(false)
        // setOpenField(null)
        // setHighlightedField(null)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [openField, name, setOpenField, setHighlightedField])

  return (
    <div ref={containerRef} className={twMerge("bg-white", disabled ? "opacity-50" : "")}>
      {editing ? (
        <div>
          <div className="mb-2">
            <div className="mb-1 font-bold">{name}</div>
          </div>
          <p className="mb-4 text-sm text-gray-dark">
            Select a striking hero image that sets the tone and draws visitors into your website.
          </p>
          <Thumbnail photoUrl={photoUrl} className="h-20 w-20" />
          {showAltText &&
            isImpersonating &&
            imageAsset &&
            !imageAsset?.resizedImageUrl.startsWith("https://healme-hero-images.s3.amazonaws.com") && (
              <div className="mt-4">
                <Label htmlFor="alt-text">Alt text</Label>
                <TextArea id="alt-text" value={altText} onChange={(e) => setAltText(e.target.value)} />
              </div>
            )}
          <div className="mt-4 flex justify-between">
            <ImageUploadButton
              practice={practice}
              handleUploadImage={handleUploadImage}
              fetching={fetching}
              aspectRatio={aspectRatio}
            />
            <div className="flex justify-end gap-4">
              {showAltText &&
                isImpersonating &&
                imageAsset &&
                !imageAsset?.resizedImageUrl.startsWith("https://healme-hero-images.s3.amazonaws.com") && (
                  <Button
                    disabled={fetching || updating || altText === imageAsset.altText}
                    size="small"
                    onClick={handleSave}>
                    Save Alt Text
                  </Button>
                )}
              <Button
                disabled={fetching || updating}
                size="small"
                type="tertiary"
                onClick={() => {
                  setEditing(false)
                  setOpenField(null)
                  setHighlightedField(null)
                }}>
                Done
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="mr-4 flex-1">
            <Typography variant="title">{name}</Typography>
            <Thumbnail photoUrl={photoUrl} />
          </div>
          <div className="flex-none">
            <button
              className={twMerge("font-bold text-teal underline", disabled ? "pointer-events-none" : "")}
              onClick={() => {
                if (disabled) return
                setEditing(true)
                setOpenField(name)
                setHighlightedField(name)
                document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" })
              }}>
              Edit
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default HeroImageUpload
