import { ComputerDesktopIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"

import AcceptTermsModal from "../../components/shared/AcceptTermsModal"
import { Box } from "../../components/shared/Layout"
import ProProfileThemeFive from "../ProProfileThemeFive/ProProfileThemeFive"
import ProProfileThemeFour from "../ProProfileThemeFour/ProProfileThemeFour"
import ProProfileThemeOne from "../ProProfileThemeOne/ProProfileThemeOne"
import ProProfileThemeThree from "../ProProfileThemeThree/ProProfileThemeThree"
import ProProfileThemeTwo from "../ProProfileThemeTwo/ProProfileThemeTwo"
import { useWebsiteBuilder } from "../WebsiteBuilder/WebsiteBuilderContext"

import "../../components/shared/wysiwyg.sass"

const WebsitePreviewView = ({ children }) => {
  const { practice, previewedTheme, highlightedField } = useWebsiteBuilder()
  const [viewMode, setViewMode] = useState("desktop")

  const viewportWidth = window.innerWidth

  const scale = (viewportWidth - 696) / viewportWidth
  const invertScale = 1 / scale
  const contentStyles = {
    width: `calc((100vw - 696px) / ${scale})`,
    transform: `scale(${scale})`,
    transformOrigin: "top left",
    height: `calc(100vh*${invertScale})`,
    overflow: "auto"
  }
  const wrapperStyles = {
    width: `calc((100vw - 696px))`,
    height: `calc(100vh)`,
    overflow: "hidden"
  }

  const renderTheme = () => {
    switch (previewedTheme.name) {
      case "zen":
        return (
          <ProProfileThemeOne
            practice={practice}
            highlight={highlightedField}
            styles={contentStyles}
            viewingInBuilder={true}
          />
        )
      case "balance":
        return (
          <ProProfileThemeTwo
            practice={practice}
            highlight={highlightedField}
            styles={contentStyles}
            viewingInBuilder={true}
          />
        )
      case "serenity":
        return (
          <ProProfileThemeThree
            practice={practice}
            highlight={highlightedField}
            styles={contentStyles}
            viewingInBuilder={true}
          />
        )
      case "harmony":
        return (
          <ProProfileThemeFour
            practice={practice}
            highlight={highlightedField}
            styles={contentStyles}
            viewingInBuilder={true}
          />
        )
      case "vitality":
        return (
          <ProProfileThemeFive
            practice={practice}
            highlight={highlightedField}
            styles={contentStyles}
            viewingInBuilder={true}
          />
        )
    }
  }

  return (
    <div className="flex justify-between">
      <style>
        {`
          .font-paragraph {
            font-family: ${practice.theme.fontParagraph} !important;
            font-weight: ${practice.theme.fontParagraphRegularWeight} !important;
          }
        `}
      </style>
      <div className="fixed left-[200px] right-[496px] top-[34px] z-30 flex items-center justify-center md:hidden">
        <button
          className={`h-10 px-2 pb-4 ${viewMode === "desktop" ? "text-gray" : "border-b-4 border-black text-black"}`}
          onClick={() => setViewMode("mobile")}>
          <DevicePhoneMobileIcon className="h-6 w-6" />
        </button>
        <button
          className={`h-10 px-2 pb-4 ${viewMode === "mobile" ? "text-gray" : "border-b-4 border-black text-black"}`}
          onClick={() => setViewMode("desktop")}>
          <ComputerDesktopIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="font-paragraph overflow-hidden md:hidden" style={wrapperStyles}>
        {viewMode === "desktop" ? (
          <>{renderTheme()}</>
        ) : (
          <div className="relative flex h-[calc(100vh-72px)] w-full flex-col items-center bg-gray-ultralight">
            <div className="flex w-full justify-center">
              <Box className="my-8 bg-orange-light p-6">Editing is disabled while previewing mobile</Box>
            </div>
            <iframe
              width={390}
              height={844}
              src={`/${practice.user.vanitySlug || practice.user.slug}?iframe_preview=true`}
            />
          </div>
        )}
      </div>
      <div
        className={`fixed right-0 h-screen min-h-100 w-[496px] flex-none overflow-y-scroll border-l border-gray-light bg-white pb-24 md:w-full ${
          viewMode === "mobile" ? "pointer-events-none opacity-25" : ""
        }`}>
        {children}
      </div>
      <AcceptTermsModal showModal={practice.showTermsModal} />
    </div>
  )
}

export default WebsitePreviewView
