import React, { useState, useEffect, useRef } from "react"
import { twMerge } from "tailwind-merge"

import { VALIDATION_ERROR_INPUT_CLASSNAMES, ValidationError } from "./Inputs"

import "react-quill/dist/quill.snow.css"

const toolbarOptions = [
  [{ header: [false, 2, 3, 4] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline"],
  ["link"],
  [{ align: [] }]
]

const QuillEditor = (props) => {
  const [ReactQuill, setReactQuill] = useState(null)
  const quillRef = useRef(null)

  useEffect(() => {
    import("react-quill")
      .then((module) => {
        setReactQuill(() => module.default)
      })
      .catch((error) => console.error("Error importing ReactQuill:", error)) // eslint-disable-line no-console
  }, [])

  useEffect(() => {
    if (ReactQuill && quillRef.current) {
      const quillInstance = quillRef.current.getEditor()

      const updateTooltipPosition = () => {
        const tooltip = document.querySelector(".ql-tooltip")
        if (tooltip && tooltip.style.left && Number(tooltip.style.left.replace("px", "")) < 0) {
          tooltip.style.left = 0
        }
      }

      quillInstance.on("selection-change", updateTooltipPosition)

      return () => {
        quillInstance.off("selection-change", updateTooltipPosition)
      }
    }
  }, [ReactQuill])

  if (!ReactQuill) {
    return <div>Loading editor...</div>
  }

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        className={twMerge("w-full", props.className, props.validationError && VALIDATION_ERROR_INPUT_CLASSNAMES)}
        {...props}
        modules={{
          toolbar: toolbarOptions
        }}
      />
      {props.validationError && !!props.validationError.length && (
        <ValidationError>{props.validationError}</ValidationError>
      )}
    </div>
  )
}

export default QuillEditor
