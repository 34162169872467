import React, { useState, useEffect } from "react"

import { Button } from "../../components/shared/Buttons"
import { InputWithLabel, TextArea } from "../../components/shared/Inputs"
import Typography from "../../components/shared/Typography"
import { urqlClient } from "../../utils/utils"

import PhoneValidation from "./PhoneValidation"

const ChatWidget = ({ practice, currentUser, toast }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showOtpInput, setShowOtpInput] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState("")
  const [question, setQuestion] = useState("")
  const [hasAgreed, setHasAgreed] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [quickMessage, setQuickMessage] = useState("")
  const [showQuickChat, setShowQuickChat] = useState(true)
  const [showFullForm, setShowFullForm] = useState(false)
  const [validationError, setValidationError] = useState(null)
  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    email: "",
    phone: "",
    question: ""
  })
  const [showThankYou, setShowThankYou] = useState(false)
  const [showPhoneValidation, setShowPhoneValidation] = useState(false)
  const [countdown, setCountdown] = useState(45)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const user = currentUser?.user
  const showToast = toast?.showToast || (() => {})

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById("about")
      if (aboutSection) {
        const rect = aboutSection.getBoundingClientRect()
        const isVisible = rect.top >= 0 && rect.top <= window.innerHeight

        if (isVisible && !showNotification && !isOpen) {
          setShowNotification(true)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [showNotification, isOpen])

  useEffect(() => {
    if (showOtpInput) {
      setIsModalOpen(true)
      setCountdown(45)
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            return 0
          }
          return prev - 1
        })
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [showOtpInput])

  const resetForm = () => {
    setFirstName("")
    setEmail("")
    setPhone("")
    setCode("")
    setQuestion("")
    setHasAgreed(false)
    setShowOtpInput(false)
  }

  const validateForm = () => {
    const errors = {}
    let isValid = true

    if (!firstName.trim()) {
      errors.firstName = "First name is required"
      isValid = false
    }
    if (!email.trim()) {
      errors.email = "Email is required"
      isValid = false
    }
    if (!phone.trim()) {
      errors.phone = "Phone number is required"
      isValid = false
    }
    if (!question.trim()) {
      errors.question = "Message is required"
      isValid = false
    }
    if (!hasAgreed) {
      errors.agreement = "Please confirm you are a client or prospective client"
      isValid = false
    }

    setFieldErrors(errors)
    return isValid
  }

  const handleSubmit = async () => {
    if (!validateForm()) {
      return
    }

    if (user) {
      await submitMessage()
    } else if (showOtpInput) {
      verifyCode()
    } else {
      sendVerification()
    }
  }

  const sendVerification = () => {
    if (!phone.trim()) {
      setValidationError("Please enter your phone number")
      return
    }

    const formattedPhone = phone.replace(/\D/g, "")
    const e164Phone = formattedPhone.startsWith("1") ? `+${formattedPhone}` : `+1${formattedPhone}`

    urqlClient
      .mutation(
        `mutation($phone: String!) {
          phoneVerification(phone: $phone) { result errors }
        }`,
        { phone: e164Phone }
      )
      .toPromise()
      .then((result) => {
        if (result.data?.phoneVerification?.result === "success") {
          setShowOtpInput(true)
          setValidationError(null)
        } else {
          setValidationError(result.data?.phoneVerification?.errors?.[0] || "Failed to send verification code")
        }
      })
      .catch((error) => {
        console.log(error) // eslint-disable-line no-console
        setValidationError("Failed to send verification code. Please try again.")
      })
  }

  const verifyCode = () => {
    if (!code.trim()) {
      setValidationError("Please enter the verification code")
      return
    }

    const formattedPhone = phone.replace(/\D/g, "")
    const e164Phone = formattedPhone.startsWith("1") ? `+${formattedPhone}` : `+1${formattedPhone}`

    urqlClient
      .mutation(
        `mutation($phone: String!, $code: String!, $device: String!, $firstName: String, $email: String) {
          phoneLogin(phone: $phone, code: $code, device: $device, firstName: $firstName, email: $email) {
            result
            token
            errors
          }
        }`,
        {
          phone: e164Phone,
          code: code.trim(),
          device: "web",
          firstName: firstName.trim(),
          email: email.trim()
        }
      )
      .toPromise()
      .then((result) => {
        if (result.data?.phoneLogin?.result === "success") {
          submitMessage()
          setValidationError(null)
        } else {
          setValidationError(result.data?.phoneLogin?.errors?.[0] || "Verification failed")
        }
      })
      .catch((error) => {
        console.log(error) // eslint-disable-line no-console
        setValidationError("Verification failed. Please try again.")
      })
  }

  const submitMessage = async () => {
    if (!question.trim()) {
      setValidationError("Please enter a message")
      return
    }

    const practitionerId = practice.user.id
    const messageArgs = {
      practitionerId,
      message: question,
      senderName: firstName.trim(),
      senderEmail: email.trim(),
      senderPhone: phone.trim()
    }

    const result = await urqlClient
      .mutation(
        `mutation($practitionerId: ID!, $message: String!, $senderName: String!, $senderEmail: String!, $senderPhone: String!) {
          sendMessage(practitionerId: $practitionerId, message: $message, senderName: $senderName, senderEmail: $senderEmail, senderPhone: $senderPhone) {
            result
            errors
          }
        }`,
        messageArgs
      )
      .toPromise()

    if (result.data?.sendMessage?.result === "success") {
      setShowThankYou(true)
      showToast("Your message has been sent! 🎉")
      setTimeout(() => {
        setIsOpen(false)
        resetForm()
        setShowThankYou(false)
      }, 3000)
    } else {
      setValidationError(result.data?.sendMessage?.errors?.toString())
    }
  }

  const handleNotificationClick = () => {
    setIsOpen(true)
    setShowNotification(false)
    setShowQuickChat(true)
    setShowFullForm(false)
  }

  const handleQuickMessageSubmit = () => {
    if (!quickMessage.trim()) return
    setQuestion(quickMessage)
    setShowQuickChat(false)
    setShowFullForm(true)
  }

  const handlePhoneValidationSuccess = () => {
    setShowPhoneValidation(false)
    setShowOtpInput(true)
  }

  const handleNextStep = () => {
    handleSubmit()
  }

  const handleResendCode = () => {
    sendVerification()
    setCountdown(45)
  }

  const handleCloseModal = () => {
    setShowOtpInput(false)
    setIsModalOpen(false)
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.shiftKey && event.key === "Enter") {
        event.preventDefault()
        handleNextStep()
      }
    }

    window.addEventListener("keydown", handleKeyPress)
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [])

  if (!practice) return null

  return (
    <>
      {showPhoneValidation ? (
        <PhoneValidation onSuccess={handlePhoneValidationSuccess} />
      ) : (
        <>
          {showNotification && !isOpen && (
            <div
              className="fixed bottom-24 right-6 z-[9999] flex cursor-pointer items-center gap-3 rounded-lg bg-white p-4 shadow-lg transition-opacity duration-200"
              style={{ maxWidth: "min(300px, calc(100vw-48px))", transform: "translateX(0)" }}
              onClick={handleNotificationClick}>
              <div className="relative">
                <div className="h-12 w-12 overflow-hidden rounded-full">
                  <img
                    src={practice.user?.profilePhotoUrl}
                    alt={practice.user?.firstName}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="absolute -right-1 -top-1 h-4 w-4 rounded-full border-2 border-white bg-teal"></div>
              </div>
              <div className="grow">
                <Typography>Let me know if you have any questions!</Typography>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setShowNotification(false)
                }}
                className="absolute right-2 top-2 text-gray-dark hover:text-gray-dark">
                ✕
              </button>
            </div>
          )}

          {isOpen && (
            <div className="fixed inset-0 z-[9999] flex items-end justify-end pb-24 pr-6 sm:items-center sm:justify-center sm:p-0">
              <div className="fixed inset-0 bg-black/20" onClick={() => setIsOpen(false)} />
              <div className="relative w-[400px] max-w-[calc(100vw-48px)] overflow-hidden rounded-lg bg-white shadow-xl sm:rounded-lg">
                <div className="flex h-14 items-center justify-between bg-teal px-4">
                  <div className="flex items-center gap-2">
                    <div className="relative">
                      <div className="h-8 w-8 overflow-hidden rounded-full border-2 border-white">
                        <img
                          src={practice.user?.profilePhotoUrl}
                          alt={practice.user?.firstName}
                          className="h-full w-full object-cover"
                        />
                      </div>
                      <div className="absolute -right-0.5 -top-0.5 h-3 w-3 rounded-full border-2 border-white bg-green"></div>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-white">{practice.user?.firstName}</span>
                      <span className="text-xs text-white">Online</span>
                    </div>
                  </div>
                  <button
                    className="h-8 w-8 rounded-md text-white hover:bg-teal-dark/20"
                    onClick={() => setIsOpen(false)}>
                    ✕
                  </button>
                </div>

                {showThankYou ? (
                  <div className="flex flex-col items-center justify-center space-y-4 p-8">
                    <div className="text-2xl">Thank You! 🎉</div>
                    <p className="text-center text-gray-dark">
                      Your message has been sent to {practice.user?.firstName}.
                      {" You will get an email when a response is received."}
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col space-y-4 p-4">
                    <div className="flex items-start gap-2">
                      <div className="h-8 w-8 overflow-hidden rounded-full">
                        <img
                          src={practice.user?.profilePhotoUrl}
                          alt={practice.user?.firstName}
                          className="h-full w-full object-cover"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="rounded-lg rounded-tl-none bg-gray-ultralight px-3 py-2 text-sm">
                          Let me know if you have any questions!
                        </div>
                      </div>
                    </div>

                    {showQuickChat && !showFullForm && (
                      <div className="flex items-center gap-2">
                        <div className="relative flex-1">
                          <TextArea
                            value={quickMessage}
                            onChange={(e) => setQuickMessage(e.target.value)}
                            placeholder="Write a message..."
                            className="min-h-10 resize-none rounded-lg border bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-gray-dark focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            rows={1}
                          />
                        </div>
                        <button
                          onClick={handleQuickMessageSubmit}
                          className="inline-flex h-12 w-12 items-center justify-center whitespace-nowrap rounded-full bg-teal text-sm font-medium text-white ring-offset-white transition-colors hover:bg-teal-dark focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                          disabled={!quickMessage.trim()}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-5 w-5">
                            <path d="M3.478 2.404a.75.75 0 00-.926.941l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.404z" />
                          </svg>
                        </button>
                      </div>
                    )}

                    {showFullForm && (
                      <div className="flex flex-col space-y-4">
                        {!currentUser && (
                          <>
                            <InputWithLabel
                              label="First Name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                              error={fieldErrors.firstName}
                              validationError={fieldErrors.firstName}
                            />
                            <InputWithLabel
                              label="Email"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              error={fieldErrors.email}
                              validationError={fieldErrors.email}
                            />
                            <InputWithLabel
                              label="Phone"
                              type="tel"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              error={fieldErrors.phone}
                              validationError={fieldErrors.phone}
                            />
                            <TextArea
                              label="Message"
                              value={question}
                              onChange={(e) => setQuestion(e.target.value)}
                              error={fieldErrors.question}
                              validationError={fieldErrors.question}
                              rows={4}
                            />
                            {showOtpInput && isModalOpen && (
                              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
                                <div className="rounded-lg bg-white p-6 text-center shadow-lg">
                                  <p className="mb-4">Enter the verification code we sent to your phone:</p>
                                  <InputWithLabel
                                    label="Verification Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    maxLength={6}
                                    validationError={validationError}
                                  />
                                  <p className="mt-2 text-sm text-gray-dark">
                                    {countdown > 0 ? (
                                      `Resend code in ${countdown} seconds`
                                    ) : (
                                      <span className="cursor-pointer text-teal" onClick={handleResendCode}>
                                        Resend Code
                                      </span>
                                    )}
                                  </p>
                                  <div className="mt-4 flex items-center justify-center gap-4">
                                    <Button onClick={handleNextStep}>Verify Code</Button>
                                    <button onClick={handleCloseModal} className="text-xs text-gray-dark">
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        <div className="flex items-start gap-2">
                          <input
                            type="checkbox"
                            id="agreement"
                            checked={hasAgreed}
                            onChange={(e) => setHasAgreed(e.target.checked)}
                            className="mt-1"
                          />
                          <label htmlFor="agreement" className="text-sm text-gray-dark">
                            I confirm I am a client or prospective client of {practice.user?.firstName}
                          </label>
                        </div>
                        {fieldErrors.agreement && <p className="text-sm text-red">{fieldErrors.agreement}</p>}
                        {validationError && <p className="text-sm text-red">{validationError}</p>}
                        <Button onClick={handleNextStep} className="w-full">
                          Send Message
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <button
            onClick={() => {
              setIsOpen(!isOpen)
              setShowNotification(false)
              setShowQuickChat(true)
              setShowFullForm(false)
            }}
            className="fixed bottom-6 right-6 z-[9999] flex h-16 w-16 items-center justify-center rounded-full bg-teal text-white shadow-lg hover:bg-teal-dark"
            style={{ transform: "translateX(0)" }}>
            <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
              />
            </svg>
          </button>
        </>
      )}
    </>
  )
}

export default ChatWidget
