import React, { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

import AnimatedSection from "../ProProfileShared/AnimatedSection"
import HealMeVerifiedBadge from "../ProProfileShared/HealMeVerifiedBadge"
import Review from "../ProProfileShared/Review"
import ReviewStars from "../ProProfileShared/ReviewStars"
import SectionTitle from "../ProProfileShared/SectionTitle"
import SeeMore from "../ProProfileShared/SeeMore"

const ReviewsSection = ({ practice, theme, className, backgroundColor, color, starsClassName }) => {
  backgroundColor ||= theme.colorPrimaryDark
  color ||= theme.colorTextLight

  const isMobile = window.innerWidth < 768
  const defaultReviewsShown = isMobile ? 4 : 11
  const reviews = practice.reviews.filter((review) => review.review)
  const [expanded, setExpanded] = useState(false)
  const [expandedReviewSwitch, setExpandedReviewSwitch] = useState(false)

  const reviewsContainerRef = useRef(null)

  const reviewsImage = practice.imageAssets.find((asset) => asset.description === "reviews_section")

  useEffect(() => {
    if (reviewsContainerRef.current) {
      reviewsContainerRef.current.style.maxHeight = expanded ? `${reviewsContainerRef.current.scrollHeight}px` : "0px"
    }
  }, [expanded, reviews, expandedReviewSwitch])

  // Trick to update the maxHeight of the reviews container when a review is expanded/collapsed
  const onExpand = () => {
    setExpandedReviewSwitch((prev) => !prev)
  }

  return (
    <section
      id="reviews"
      className={twMerge("scroll-mt-24 py-32 md:pt-0 min_md:mt-20", className)}
      style={{ backgroundColor, color }}>
      <div className="container mx-auto">
        <div className="hidden md:block">
          <img src={reviewsImage.resizedImageUrl} alt={reviewsImage.altText} className="w-full object-cover" />
        </div>
        <AnimatedSection>
          <div className="flex gap-16 md:w-full md:px-5">
            <div className="relative w-1/2 md:hidden">
              <img
                src={reviewsImage.resizedImageUrl}
                alt={reviewsImage.altText}
                className="absolute bottom-0 right-0 object-cover"
              />
            </div>
            <div className="flex w-1/2 flex-col md:w-full">
              <SectionTitle className="mb-4 md:mb-0 md:mt-8 md:text-center" theme={theme}>
                My Reviews
              </SectionTitle>
              <div className={twMerge("flex items-center gap-4 md:justify-center", starsClassName)}>
                <ReviewStars rating={practice.averageRating} starColor={theme.colorReviewStars} />
                <span>{practice.reviews.length} reviews</span>
              </div>
              <div className="my-8 flex md:justify-center">
                <HealMeVerifiedBadge theme={theme} backgroundColor={backgroundColor} color={color} />
              </div>
              {reviews.slice(0, 1).map((review) => (
                <Review
                  key={`review-${review.id}`}
                  review={review}
                  practice={practice}
                  starColor={theme.colorReviewStars}
                  className="min_md:min-h-36"
                  onExpand={onExpand}
                  onCollapse={onExpand}
                />
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-16 md:mt-8 md:grid-cols-1 md:gap-8 md:px-5">
            {reviews.slice(1, defaultReviewsShown).map((review) => (
              <Review
                key={`review-${review.id}`}
                review={review}
                practice={practice}
                starColor={theme.colorReviewStars}
                onExpand={onExpand}
                onCollapse={onExpand}
              />
            ))}
          </div>
        </AnimatedSection>
        {expanded && <div className="h-10 md:h-8" />}
        <div
          ref={reviewsContainerRef}
          className="grid grid-cols-2 gap-16 overflow-hidden transition-all duration-1000 lg:grid-cols-1 md:gap-8 md:px-5"
          style={{ maxHeight: 0 }}>
          {reviews.slice(defaultReviewsShown).map((review) => (
            <Review
              key={`review-${review.id}`}
              review={review}
              practice={practice}
              starColor={theme.colorReviewStars}
              onExpand={onExpand}
              onCollapse={onExpand}
            />
          ))}
        </div>
        {reviews.length > defaultReviewsShown && (
          <SeeMore
            className="pt-10 text-opacity-50"
            buttonClassName="hover:text-[var(--hover-color)]"
            buttonStyle={{ color, "--hover-color": theme.colorAccent }}
            buttonText="See more reviews"
            onClick={() => {
              if (expanded) document.getElementById("reviews").scrollIntoView({ behavior: "smooth" })
              setExpanded(!expanded)
            }}
          />
        )}
      </div>
    </section>
  )
}

export default ReviewsSection
