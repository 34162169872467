import React, { createContext, useContext, useState } from "react"

import Toast from "../components/shared/Toast"

export const ToastContext = createContext()

const DEFAULT_TOAST_PARAMS = {
  type: "success",
  position: "bottomCenter",
  timeout: 6000
}

export const ToastProvider = ({ children }) => {
  const [alertState, setAlertState] = useState({
    visible: false,
    content: "",
    ...DEFAULT_TOAST_PARAMS
  })

  const showToast = (params) => {
    if (typeof params === "string") params = { content: params }
    const { content, type, position, timeout } = { ...DEFAULT_TOAST_PARAMS, ...params }
    setAlertState({ visible: true, content, type, position, timeout })
  }

  const hideToast = () => setAlertState((prev) => ({ ...prev, visible: false }))

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      <>
        {children}
        <Toast
          visible={alertState.visible}
          setVisible={(visible) => setAlertState((prev) => ({ ...prev, visible }))}
          position={alertState.position}
          type={alertState.type}
          timeout={alertState.timeout}>
          {alertState.content}
        </Toast>
      </>
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
